<template>
  <component
    :is="document.document"
    v-bind="document.ds_json_props"
    v-on="events"
    :doc="doc"
    :settings="settings"
  />
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'Matrix',
  computed: {
    ...mapState('document', ['document']),
  },
  props: {
    events: {
      type: Object,
      required: false,
      default() {
        return;
      },
    },
    doc: {
      required: false,
    },
    settings: {
      type: Object,
      required: false,
      default() {
        return;
      },
    },
  },
};
</script>
