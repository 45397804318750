<template>
  <div>
    <div
      v-if="modal"
      class="z-0 h-full fixed bottom-0 inset-x-0 px-4 pb-6 sm:inset-0 sm:p-0 sm:flex sm:items-center sm:justify-center"
    >
      <div class="h-full fixed inset-0">
        <div class="fixed h-full inset-0 bg-gray-500 opacity-50"></div>
      </div>

      <div
        class="z-50 bg-white rounded-lg px-4 pt-5 pb-4 overflow-hidden shadow-xl transform transition-all sm:max-w-lg sm:w-full sm:p-6"
        role="dialog"
        aria-modal="true"
        aria-labelledby="modal-headline"
      >
        <div>
          <div
            class="mx-auto flex items-center justify-center h-12 w-12 rounded-full bg-green-100"
          >
            <svg
              class="h-6 w-6 text-green-600"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M5 13l4 4L19 7"
              />
            </svg>
          </div>
          <div class="mt-3 text-center sm:mt-5 z-50">
            <h3
              class="text-lg leading-6 font-medium text-gray-900"
              id="modal-headline"
            >
              Aprenda a integrar seu documento
            </h3>
            <div class="my-4">
              <p class="text-sm leading-5 text-gray-700 text-left">
                O código a seguir é um código que integra nossa página a sua!
                Você só precisa colocar o código do documento dentro da sua
                própria página de termos ou privacidade que nós entregamos no
                seu próprio sistema o documento.
              </p>
            </div>
            <div class="mt-2" v-if="embed.length > 0">
              <label
                class="block text-left text-gray-700 text-sm mb-1"
                for="embed"
                >Código embed:</label
              >
              <textarea
                class="form-input text-sm text-gray-900"
                id="embed"
                rows="3"
                v-model="embed"
              ></textarea>

              <label
                class="block mt-3 text-left text-gray-700 text-sm mb-1"
                for="direct"
                >Link direto:</label
              >
              <textarea
                class="form-input text-sm text-gray-900"
                id="direct"
                rows="1"
                v-model="directLink"
              ></textarea>
            </div>
          </div>
        </div>
        <div
          class="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense"
        >
          <span class="flex w-full rounded-md shadow-sm sm:col-start-2">
            <button
              type="button"
              @click="handleCopyDocument()"
              class="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 bg-indigo-600 text-base leading-6 font-medium text-white shadow-sm hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Copiar
            </button>
          </span>
          <span
            class="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1"
          >
            <button
              @click="modal = false"
              type="button"
              class="inline-flex justify-center w-full rounded-md border border-gray-300 px-4 py-2 bg-white text-base leading-6 font-medium text-gray-700 shadow-sm hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue transition ease-in-out duration-150 sm:text-sm sm:leading-5"
            >
              Sair
            </button>
          </span>
        </div>
      </div>
    </div>

    <button
      class="fixed z-50 flex border-red-200 bg-white bottom-20 right-20 py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-red-700 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
      @click="openEditor = true"
      v-else-if="verifyIfCanShowPersonalizateDocument() && !openEditor"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        width="18"
        class="mr-1"
        stroke="currentColor"
      >
        <path
          stroke-linecap="round"
          stroke-linejoin="round"
          stroke-width="2"
          d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z"
        />
      </svg>
      Personalizar meu documento
    </button>

    <div
      class="fixed inset-0 overflow-hidden z-50"
      v-if="verifyIfCanShowPersonalizateDocument() && openEditor"
    >
      <div class="absolute inset-0 overflow-hidden z-50">
        <section class="absolute inset-y-0 pl-16 max-w-full right-0 flex z-50">
          <div class="max-w-md pt-14 z-50">
            <div
              class="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl"
            >
              <div class="flex-1 h-0 overflow-y-auto">
                <header class="space-y-1 py-6 px-4 bg-indigo-700 sm:px-6">
                  <div class="flex items-center justify-between space-x-3">
                    <h2 class="text-lg leading-7 font-medium text-white">
                      Personalizar Documento
                    </h2>
                    <div
                      class="h-7 flex items-center"
                      @click="openEditor = false"
                    >
                      <button
                        aria-label="Close panel"
                        class="text-indigo-200 hover:text-white transition ease-in-out duration-150"
                      >
                        <svg
                          class="h-6 w-6"
                          fill="none"
                          viewBox="0 0 24 24"
                          stroke="currentColor"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M6 18L18 6M6 6l12 12"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                  <div>
                    <p class="text-sm leading-5 text-indigo-300">
                      Comece agora a personalizar o seu documento com a cara da
                      sua marca.
                    </p>
                  </div>
                </header>
                <div class="flex-1 flex flex-col justify-between">
                  <div class="px-4 divide-y divide-gray-200 sm:px-6">
                    <div class="space-y-6 pt-6 pb-5">
                      <div class="space-y-1">
                        <label
                          for="country"
                          class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Elemento textual
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                          <div class="mb-6">
                            <select
                              id="country"
                              v-model="settingsOption"
                              class="block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            >
                              <option value="heading" default>
                                Títulos (Headings)
                              </option>
                              <option value="body">Textos</option>
                              <option value="link">Links</option>
                            </select>
                          </div>
                        </div>
                        <div
                          class="bg-gray-200 rounded-md shadow-sm py-2 px-2"
                          v-if="settingsOption === 'heading'"
                        >
                          <label
                            for="font"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Fonte de texto
                          </label>
                          <select
                            id="font"
                            v-model="settings.settingsHeading.focused_font"
                            class="mt-2 block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            v-bind:style="{
                              'font-family':
                                settings.settingsHeading.focused_font,
                            }"
                          >
                            <option value="" disabled selected>
                              Selecionar
                            </option>
                            <option
                              v-for="font in settingsDefault.settingsHeading
                                .available_fonts"
                              :key="font"
                              v-bind:value="font"
                              v-bind:style="{ fontFamily: font }"
                            >
                              {{ font }}
                            </option>
                          </select>
                          <div class="flex mb-2 flex-1 space-between">
                            <label
                              for="size"
                              class="w-1/2 mt-3 block text-sm font-medium leading-5 text-gray-700"
                            >
                              Tamanho de fonte
                            </label>
                            <label
                              class="ml-4 w-1/2 mt-3 block text-sm font-medium leading-5 text-gray-700"
                            >
                              Cor
                            </label>
                          </div>
                          <div class="flex">
                            <select
                              id="size"
                              v-model="settings.settingsHeading.focused_size"
                              class="align-bottom justify-center block form-select w-1/2 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            >
                              <option value="" disabled selected>
                                Tamanho de fonte
                              </option>
                              <option
                                v-for="fontSize in settingsDefault
                                  .settingsHeading.available_size"
                                :key="fontSize"
                                :value="fontSize"
                              >
                                {{ fontSize }}
                              </option>
                            </select>

                            <v-swatches
                              class="ml-4 relative p-0 mb-0 h-full w-1/2"
                              v-model="settings.settingsHeading.color"
                              show-fallback
                              fallback-input-type="color"
                              popover-x="left"
                            ></v-swatches>
                          </div>
                        </div>
                        <div
                          class="bg-gray-200 rounded-md shadow-sm py-2 px-2"
                          v-if="settingsOption === 'body'"
                        >
                          <label
                            for="font"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Fonte de texto
                          </label>
                          <select
                            id="font"
                            v-model="settings.settingsBody.focused_font"
                            class="mt-2 block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            v-bind:style="{
                              'font-family': settings.settingsBody.focused_font,
                            }"
                          >
                            <option value="" disabled selected>
                              Selecionar
                            </option>
                            <option
                              v-for="font in settingsDefault.settingsBody
                                .available_fonts"
                              :key="font"
                              v-bind:value="font"
                              v-bind:style="{ fontFamily: font }"
                            >
                              {{ font }}
                            </option>
                          </select>
                          <div class="flex mb-2 flex-1 space-between">
                            <label
                              for="size"
                              class="w-1/2 mt-3 block text-sm font-medium leading-5 text-gray-700"
                            >
                              Tamanho de fonte
                            </label>
                            <label
                              class="ml-4 w-1/2 mt-3 block text-sm font-medium leading-5 text-gray-700"
                            >
                              Cor
                            </label>
                          </div>
                          <div class="flex">
                            <select
                              id="size"
                              v-model="settings.settingsBody.focused_size"
                              class="align-bottom justify-center block form-select w-1/2 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            >
                              <option value="" disabled selected>
                                Tamanho de fonte
                              </option>
                              <option
                                v-for="fontSize in settingsDefault.settingsBody
                                  .available_size"
                                :key="fontSize"
                                v-bind:value="fontSize"
                              >
                                {{ fontSize }}
                              </option>
                            </select>

                            <v-swatches
                              class="ml-4 relative p-0 mb-0 h-full w-1/2"
                              v-model="settings.settingsBody.color"
                              show-fallback
                              fallback-input-type="color"
                              popover-x="left"
                            ></v-swatches>
                          </div>
                        </div>
                        <div
                          class="bg-gray-200 rounded-md shadow-sm py-2 px-2"
                          v-if="settingsOption === 'link'"
                        >
                          <label
                            for="font"
                            class="block text-sm font-medium leading-5 text-gray-700"
                          >
                            Fonte de texto
                          </label>
                          <select
                            id="font"
                            v-model="settings.settingsLink.focused_font"
                            class="mt-2 block form-select w-full transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            v-bind:style="{
                              'font-family': settings.settingsLink.focused_font,
                            }"
                          >
                            <option value="" disabled selected>
                              Selecionar
                            </option>
                            <option
                              v-for="font in settingsDefault.settingsLink
                                .available_fonts"
                              v-bind:value="font"
                              :key="font"
                              v-bind:style="{ fontFamily: font }"
                            >
                              {{ font }}
                            </option>
                          </select>
                          <div class="flex mb-2 flex-1 space-between">
                            <label
                              for="size"
                              class="w-1/2 mt-3 block text-sm font-medium leading-5 text-gray-700"
                            >
                              Tamanho de fonte
                            </label>
                            <label
                              class="ml-4 w-1/2 mt-3 block text-sm font-medium leading-5 text-gray-700"
                            >
                              Cor
                            </label>
                          </div>
                          <div class="flex">
                            <select
                              id="size"
                              v-model="settings.settingsLink.focused_size"
                              class="align-bottom justify-center block form-select w-1/2 transition duration-150 ease-in-out sm:text-sm sm:leading-5"
                            >
                              <option value="" disabled selected>
                                Tamanho de fonte
                              </option>
                              <option
                                v-for="fontSize in settingsDefault.settingsLink
                                  .available_size"
                                :key="fontSize"
                                v-bind:value="fontSize"
                              >
                                {{ fontSize }}
                              </option>
                            </select>

                            <v-swatches
                              class="ml-4 relative p-0 mb-0 h-full w-1/2"
                              v-model="settings.settingsLink.color"
                              show-fallback
                              fallback-input-type="color"
                              popover-x="left"
                            ></v-swatches>
                          </div>
                        </div>
                      </div>
                      <div class="space-y-1">
                        <label
                          for="country"
                          class="block text-sm font-medium leading-5 text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Cor de fundo do documento
                        </label>
                        <div class="mt-1 sm:mt-0 sm:col-span-2">
                          <div
                            class="bg-gray-200 rounded-md shadow-sm py-2 px-2 mb-3"
                          >
                            <v-swatches
                              class="relative p-0 mb-0 h-full w-1/2"
                              v-model="settings.settingsExtra.backgroundColor"
                              show-fallback
                              fallback-input-type="color"
                              popover-x="left"
                            ></v-swatches>
                          </div>
                        </div>
                        <label class="flex items-center">
                          <input
                            v-model="settings.settingsExtra.showPowered"
                            type="checkbox"
                            class="mr-2 cursor-pointer form-checkbox text-indigo-500"
                          />
                          <span
                            class="text-sm font-medium leading-5 text-gray-700"
                          >
                            Mostrar "elaborado por"
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="flex-shrink-0 px-4 py-4 space-x-4 flex justify-end">
                <span class="inline-flex rounded-md shadow-sm">
                  <button
                    type="button"
                    @click="refreshValues()"
                    class="py-2 px-4 border border-gray-300 rounded-md text-sm leading-5 font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:border-blue-300 focus:shadow-outline-blue active:bg-gray-50 active:text-gray-800 transition duration-150 ease-in-out"
                  >
                    Redefinir valores
                  </button>
                </span>
                <span class="inline-flex rounded-md shadow-sm">
                  <button
                    @click="updateStyles()"
                    type="submit"
                    class="inline-flex justify-center py-2 px-4 border border-transparent text-sm leading-5 font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-500 focus:outline-none focus:border-indigo-700 focus:shadow-outline-indigo active:bg-indigo-700 transition duration-150 ease-in-out"
                  >
                    Salvar Tema
                  </button>
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import { functions } from '@/utils/functions';
import documentService from '@/services/documentService';
import { mapGetters, mapActions, mapState } from 'vuex';
import VSwatches from 'vue-swatches';
import 'vue-swatches/dist/vue-swatches.css';
import router from '@/router';

export default {
  name: 'DocumentEditor',
  components: { VSwatches },
  computed: {
    ...mapState('auth', ['user', 'isLoggedIn']),
    ...mapState('document', ['styles', 'document']),
  },
  mixins: [functions],
  props: ['settings', 'settingsDefault'],
  data() {
    return {
      modal: false,
      embed: '',
      directLink: '',
      openEditor: false,
      settingsOption: 'heading',
      id_document: this.document?.id_document,
      nm_document: this.document?.nm_document,
      id_user: this.user?.id,
    };
  },
  created() {},
  methods: {
    ...mapActions('document', [
      'updateStylesDocument',
      'getStylesFromDocument',
    ]),
    ...mapActions('alert', ['success']),

    showModal() {
      this.selectDocument = Document;
      this.modal = true;
      this.openEditor = false;

      let serializedDocument = this.serializedNameDocument(this.nm_document);
      let id_user = this.user.id;
      let id_document = this.id_document;

      id_document = this.handleEncoderParam(id_document);
      id_user = this.handleEncoderParam(id_user);

      const url = `${location.origin}/doc/embed/${serializedDocument}/${id_document}/${id_user}`;

      this.embed = `<embed type="text/html" src="${url}" width="100%" height="100%" style="min-height: 100vh" />`;
      this.directLink = url;
    },
    handleCopyDocument() {
      let serializedDocument = this.serializedNameDocument(this.nm_document);
      let id_user = this.user.id;
      let id_document = this.id_document;

      id_document = this.handleEncoderParam(id_document);
      id_user = this.handleEncoderParam(id_user);

      const url = `${location.origin}/doc/embed/${serializedDocument}/${id_document}/${id_user}`;

      const elementAux = document.createElement('textarea');
      document.body.appendChild(elementAux);
      elementAux.value = url;
      elementAux.select();
      document.execCommand('copy');
      document.body.removeChild(elementAux);

      this.success({
        showType: 'toast',
        title: 'Código copiado com sucesso.',
      });
    },
    updateStyles() {
      if (!this.id_document) {
        this.id_document = this.document.id_document;
      }

      this.updateStylesDocument({
        id_user: Number(this.user.id),
        id_document: Number(this.id_document),
        styles: this.settings,
      });
    },
    refreshValues() {
      this.updateStylesDocument({
        id_user: Number(this.user.id),
        id_document: Number(this.id_document),
        styles: this.settingsDefault,
      });

      this.settings = this.settingsDefault;
    },
    async getStyles() {
      await this.getStylesFromDocument({
        id_user: this.user.id,
        id_document: this.id_document,
      });
    },
    redirectToEditDoc() {
      let { nm_document, id_document, id_user } = this.$route.params;

      let serializedDocument = this.serializedNameDocument(nm_document);

      router.push(
        `/doc/update/${serializedDocument}/${id_document}/${id_user}`
      );
    },
    verifyIfCanShowPersonalizateDocument() {
      if (this.document) {
        return (
          Boolean(this.document.has_access_editor) &&
          this.document.ds_json_props
        );
      } else {
        console.log('aqui');
        return false;
      }
    },
  },
};
</script>
